import { graphql, useStaticQuery } from 'gatsby';

import Layout from 'layouts/landing';

import FeaturesGrid from 'components/content-blocks/features-grid';
import Highlight from 'components/content-blocks/highlight';
import Integrations from 'components/globals/payroll-integrations';
import Yoast from 'components/globals/yoast';
import { HeroDefaultSignup } from 'components/hero/default-signup';
import FontIcon from 'components/icons';

import './styles/time-clock.scss';

export default function TimeClock() {
  const data = useStaticQuery(graphql`
    {
      allWordpressPage(filter: { slug: { eq: "landing-time-clock" } }) {
        edges {
          node {
            wordpress_id
        yoast_head_json {
          title
          description
          canonical
          og_title
          og_description
          og_image {
            url {
              source_url
            }
          }
          og_url
          og_type
          twitter_title
          twitter_description
          twitter_image {
            source_url
          }
        }
            acf {
              hero {
                title
                copy
                background_desktop_image {
                  source_url
                }
                background_desktop_image_2 {
                  source_url
                }
                signup {
                  launch_modal_button_text
                  modal_title
                  modal_submit_button_text
                  unique_id
                  legal_text
                  layout
                }
              }
              cards_1 {
                header {
                  title
                  sub_title
                  copy
                }
                card {
                  header
                  image {
                    source_url
                  }
                  title
                  copy
                  link_url
                  link_text
                }
              }
              features_grid {
                title
                sub_title
                buttons {
                  title
                  body
                  alt_text
                  icon_width
                  icon
                  mobile_screenshot {
                    source_url
                  }
                  desktop_screenshot {
                    source_url
                  }
                }
              }
              payroll_integrations {
                background_image_desktop {
                  source_url
                }
                background_image_mobile {
                  source_url
                }
                title
                sub_title
                copy
                logos {
                  logo {
                    source_url
                    alt_text
                  }
                }
                logos_combined {
                  source_url
                  alt_text
                }
              }
            }
          }
        }
      }
    }
  `);

  const pageContext = data.allWordpressPage.edges[0].node.acf;
  const yoast = data.allWordpressPage.edges[0].node.yoast_head_json;
  const hero = pageContext.hero;
  const cards1 = pageContext.cards_1;
  const featuresGrid = pageContext.features_grid;
  const integrations = pageContext.payroll_integrations;

  const heroRibbonText = [
    'Paperless Punch Cards',
    'Clock Out Reminders & Notifications',
    'Buddy Punching Prevention',
    'Payroll Accuracy & Integration',
  ];

  return (
    <Layout
      signupModalSubmit={ hero.signup.modal_submit_button_text }
      legalText={ hero.signup.legal_text }
    >
      <Yoast { ...yoast } />
      <div className="lp-time-clock-page-container" data-signal={ data.allWordpressPage.edges[0].node.wordpress_id }>
        <HeroDefaultSignup { ...hero } heroReverse />
        <section className="feature-list-small">
          <ul className="signup-form-features white">
            {heroRibbonText.map(text => (
              <li key={ text }>
                <FontIcon icon="checkmark-circled" />
                {text}
              </li>
            ))}
          </ul>
        </section>
        <Highlight { ...cards1 } hasTitle hasImage />
        <FeaturesGrid { ...featuresGrid[0] } />
        <Integrations { ...integrations } />
      </div>
    </Layout>
  );
}
